import React, { ReactNode, Dispatch, SetStateAction } from 'react';
//Components
import Button, {ButtonType} from '../../Components/Atoms/Button.tsx';

interface IHelpItem {
    children: ReactNode;
    imagePath?: string;
    className?: string;
    buttonPath?: string;
    onClickFunction?: () => void;
    isButtonExternal?: boolean;
    setPopUpOpen?: Dispatch<SetStateAction<boolean>>;
}

function HelpItem(props: IHelpItem) {

    const openPopup = () => {
        props.setPopUpOpen(true);
    };

    let helpItemClasses = ''.concat(
        " flex flex-col bg-soft-diamond lg:bg-transparent rounded-xl p-3  text-center justify-center items-center md:justify-between"
        ,props.className ? props.className : ''
    );

    return (
        <div className={helpItemClasses}>
            <div className='flex flex-col space-y-2 md:space-y-4 px-2 xs:px-0'>
                {props.children}
            </div>
            <Button type={ButtonType.Primary} isFullWidth={true} className='mt-2 md:mt-4' path={props.buttonPath} onClickFunction={openPopup} isExternal={props.isButtonExternal}>
                Saber más
            </Button>
        </div>
    );
}

export default HelpItem;