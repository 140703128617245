import React, { useState, ReactNode} from 'react';
//Assets
import { ReactComponent as ArrowShort } from '../../Assets/FirstSection/arrow-down-short.svg';
import { ReactComponent as IconPhone } from '../../Assets/FirstSection/phoneIcon.svg';
import { ReactComponent as IconPhoneWhite } from '../../Assets/FirstSection/phoneIconWhite.svg';
//Hooks
import {useIsWindowSizeBellow} from "../../hooks/useIsWindowSizeBellow.ts";
import useIntersectionObserver from '../../hooks/useIntersectionObserver.ts';
//Enums
import {Size} from "../../Enums/Size.ts";
import {Color} from "../../Enums/Color.ts";
import {HorizontalOrientation} from "../../Enums/HorizontalOrientation.ts";
import {ScreenSize} from "../../Enums/ScreenSize.ts";
//Componentes
import BodyText from '../../Components/Atoms/BodyText.tsx';
import Subtitle from '../../Components/Atoms/Subtitle.tsx';
import Title, {TitleType} from '../../Components/Atoms/Title.tsx';
import TextArea from '../../Components/Molecules/TextArea.tsx';
import Button, {ButtonType} from '../../Components/Atoms/Button.tsx';
import GeneralLink, {LinkType} from '../../Components/Atoms/GeneralLink.tsx';
import SocialIcons, {IconType} from '../../Components/Atoms/SocialIcons.tsx';
import HelpItem from '../../Components/Molecules/HelpItem.tsx';
import HelpShowMore from '../../Components/Molecules/HelpShowMore.tsx';
import HelpItemContainer from '../../Components/Organisms/HelpItemContainer.tsx';
import InfoImageCard from '../../Components/Molecules/InfoImageCard.tsx';
import InfoCard from '../../Components/Molecules/InfoCard.tsx';
import AppDeviceOption, {DeviceOption} from '../../Components/Molecules/AppDeviceOption.tsx';
import AppStepGuide from '../../Components/Molecules/AppStepGuide.tsx';
import AppStepGuideContainer from '../../Components/Organisms/AppStepGuideContainer.tsx';
import Section,{Position} from '../../Components/Organisms/Section.tsx';
import DoubleSection from '../../Components/Organisms/DoubleSection.tsx';
import IconsContainer from '../../Components/Organisms/IconsContainer.tsx';
import SliderImageCard from '../../Components/Organisms/SliderImageCard.tsx';
import FooterItem from '../../Components/Organisms/FooterItem.tsx';
import FooterIremContainer from '../../Components/Organisms/FooterIremContainer.tsx';
import Footer from '../../Components/Organisms/Footer.tsx';

function Home(props) {

    let baseUrl = window.location.protocol + "//" + window.location.hostname;
    
    // Verifica si es localhost y si hay un puerto especificado
    if (window.location.hostname === "localhost" && window.location.port) {
      baseUrl += ":" + window.location.port;
    }

    const [firstHelpIsOpen, setFirstHelpIsOpen] = useState<boolean>(false);
    const [secondHelpIsOpen, setSecondHelpIsOpen] = useState<boolean>(false);
    const [thirdHelpIsOpen, setThirdHelpIsOpen] = useState<boolean>(false);

    const [ref1, visible1] = useIntersectionObserver({ threshold: 0.5 });
    const [ref2, visible2] = useIntersectionObserver({ threshold: 0.5 });
    const [ref3, visible3] = useIntersectionObserver({ threshold: 0.5 });
    const [ref4, visible4] = useIntersectionObserver({ threshold: 0.5 });

    const reusableADNChildrens = [
        <InfoImageCard textOrientation={HorizontalOrientation.Start} imagePath="/images/ADNSection/ADNTechnology.svg">
            <Title titleType={TitleType.InfoCardTitle} size={Size.Lg} textColor={Color.Diamond}>
                TECNOLOGÍA
            </Title>
            <BodyText size={Size.Lg} textColor={Color.Diamond} isThin>
                Usamos ciencia de datos para desarrollar
                estrategias, algoritmos y herramientas que nos
                permiten simplificar el proceso inmobiliario y
                ofrecer una experiencia ágil y eficiente.
            </BodyText>
        </InfoImageCard>,
        <InfoImageCard textOrientation={HorizontalOrientation.End} imagePath="/images/ADNSection/ADNServices.svg">
            <Title titleType={TitleType.InfoCardTitle} size={Size.Lg} textColor={Color.Diamond}>
                SERVICIO
            </Title>
            <BodyText size={Size.Lg} textColor={Color.Diamond} isThin>
                Trabajamos incansablemente para mejorar la
                experiencia inmobiliaria de nuestros usuarios,
                ofreciendo soluciones innovadoras que superen
                sus
                expectativas y se adapten a sus necesidades.
            </BodyText>
        </InfoImageCard>,
        <InfoImageCard textOrientation={HorizontalOrientation.Start} imagePath="/images/ADNSection/ADNTransparency.svg">
            <Title titleType={TitleType.InfoCardTitle} size={Size.Lg} textColor={Color.Diamond}>
                TRANSPARENCIA
            </Title>
            <BodyText size={Size.Lg} textColor={Color.Diamond} isThin>
                La confianza es la base de la relación con nuestros 
                usuarios. Trabajamos con
                total transparencia, brindando
                información justa, completa y precisa para construir una
                relación
                sólida y lograr los mejores resultados.
            </BodyText>
        </InfoImageCard>
    ]

    return (
        <>
            <Section height={useIsWindowSizeBellow(ScreenSize.Mobile)?32:61.75} sectionId='home' withPaddings align={useIsWindowSizeBellow(ScreenSize.Mobile)?Position.Top:Position.Center} className=' bg-[#F4F9FF] bg-[bottom_right_-1.5rem] md:bg-[right_center] bg-55% md:bg-20% lg:bg-33% xl:bg-25% xl:bg-[90%] 2xl:bg-[75%] 2xl:bg-20%  pt-20 md:pt-0 lg:!h-[100vh]' imagePath='/images/backgrounds/HomeFirstSectionBg.png' childrenClassName={'flex flex-col h-[80%] justify-between md:h-auto md:justify-normal'}>
                <TextArea textAlign={HorizontalOrientation.Center} className='w-fit mx-auto md:mx-0 max-w-[37.5rem] lg:max-w-[70%]'>
                    <Title titleType={TitleType.Standard} size={Size.Lg} textColor={Color.BrandBlue} className=' uppercase font-700'>
                        Tecnología & Excelencia
                    </Title>
                    <Subtitle size={Size.Lg} className='font-700'>
                        {'SER PARTE DEL MUNDO INMOBILIARIO,<br/>NUNCA FUE TAN FÁCIL'}
                    </Subtitle>
                    {useIsWindowSizeBellow(ScreenSize.Mobile)?<></>:<BodyText size={Size.Xl} textColor={Color.BrandBlue} className='uppercase'>
                        {"NEGOCIOS INMOBILIARIOS 3.0"}
                    </BodyText>}
                </TextArea>
                {useIsWindowSizeBellow(ScreenSize.Mobile)?<div className='max-w-[46%] mt-auto '>
                    <BodyText size={Size.Xl} textColor={Color.BrandBlue} className='uppercase text-end'>
                        {"NEGOCIOS INMOBILIARIOS 3.0"}
                    </BodyText>
                </div>:<></>}
                <img src="/images/backgrounds/Home2ndBG.png" alt=""  className='w-full absolute bottom-0 left-0'/>
            </Section>
            <HelpShowMore isPopupOpen={firstHelpIsOpen} setPopUpOpen={setFirstHelpIsOpen}>
                <Subtitle size={Size.Sm}>
                    ¿Por qué elegir Bínculo?
                </Subtitle>
                <BodyText size={Size.Xs} textColor={Color.BrandGrey} className='max-w-[582px] text-left mt-4 md:mt-8'>
                    {"<b style='color:rgb(8, 19, 130);'>1).</b>  Nos elegís para comercializar tu propiedad.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>2).</b> Te creamos tu usuario dentro de nuestra APP para que puedas hacer el seguimiento comercial.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>3).</b> Comercializamos tu propiedad de forma profesional y masiva.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>4).</b> Concretamos la operación en el menor tiempo posible.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>5).</b> Tené la administración de tu propiedad a un solo click.<br/><br/>"
                    }
                </BodyText>
                <Button type={ButtonType.Primary} isFullWidth={false} className='mt-4 md:mt-8' path={'mailto:binculo@gmail.com'} onClickFunction={()=>setThirdHelpIsOpen(false)}>
                    Contactate
                </Button>
            </HelpShowMore>
            <HelpShowMore isPopupOpen={secondHelpIsOpen} setPopUpOpen={setSecondHelpIsOpen}>
                <Subtitle size={Size.Sm}>
                    ¿Por qué sumarte a Bínculo?
                </Subtitle>
                <BodyText size={Size.Xs} textColor={Color.BrandGrey} className='max-w-[582px] text-left mt-4 md:mt-8'>
                    {"<b style='color:rgb(8, 19, 130);'>1).</b>  Te creamos tu usuario de forma completamente gratuita.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>2).</b> Accederás a una amplia cartera de propiedades.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>3).</b> Podrás comercializarlas de forma profesional y personalizada.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>4).</b> Recibirás las búsquedas de propiedades afines a vos.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>5).</b> Concretarás operaciones de forma rápida y transparente.<br/><br/>"
                    }
                </BodyText>
                <Button type={ButtonType.Primary} isFullWidth={false} className='mt-4 md:mt-8' path={'mailto:binculo@gmail.com'} onClickFunction={()=>setThirdHelpIsOpen(false)}>
                    Contactate
                </Button>
            </HelpShowMore>
            <HelpShowMore isPopupOpen={thirdHelpIsOpen} setPopUpOpen={setThirdHelpIsOpen}>
                <Subtitle size={Size.Sm}>
                    ¿Cómo generar dinero de forma fácil y transparente?
                </Subtitle>
                <BodyText size={Size.Xs} textColor={Color.BrandGrey} className='max-w-[582px] text-left mt-4 md:mt-8'>
                    {"<b style='color:rgb(8, 19, 130);'>1).</b> Conocé a una persona que tenga su propiedad en venta o en alquiler.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>2).</b> Referí a ese propietario a Bínculo.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>3).</b> Tu referido nos elige para comercializar su propiedad.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>4).</b> Se concreta la operación.<br/><br/>"
                    +"<b style='color:rgb(8, 19, 130);'>5).</b> Recibís tu dinero.<br/><br/>"
                    }
                </BodyText>
                <Button type={ButtonType.Primary} isFullWidth={false} className='mt-4 md:mt-8' path={'mailto:binculo@gmail.com'} onClickFunction={()=>setThirdHelpIsOpen(false)}>
                    Contactate
                </Button>
            </HelpShowMore>
            <Section withPaddings height={useIsWindowSizeBellow(ScreenSize.Tablet)?150:55} align={Position.Center} className='bg-diamond py-8' isContentMax>
                <Title titleType={TitleType.Standard} size={Size.Lg} textColor={Color.OceanBlue} className=' mb-20 '>
                    ¿Cómo te podemos ayudar?
                </Title>
                <HelpItemContainer>
                    <HelpItem setPopUpOpen={setFirstHelpIsOpen}>
                        <Title titleType={TitleType.Standard} size={Size.Xs} textColor={Color.SoftBlue}>
                            ¿TENÉS UNA PROPIEDAD?
                        </Title>
                        <div className='max-h-[23.438rem] min-h-[23.438rem]'>
                            <img src="/images/helpSection/help-image-1.png" alt=""  className='max-w-[17.5rem] mx-auto'/>
                        </div>
                        <Subtitle size={Size.Sm}>
                            Tené el control de tu propiedad en un solo click
                        </Subtitle>
                        <BodyText size={Size.Xs} textColor={Color.BrandGrey}>
                            Vivimos en un mundo inmobiliario en donde los
                            propietarios invierten
                            grandes sumas de dinero en sus
                            propiedades, para terminar
                            cediendo la
                            comercialización y la administración,
                            perdiendo
                            así el control total sobre ellas.
                        </BodyText>
                    </HelpItem>
                    <HelpItem setPopUpOpen={setSecondHelpIsOpen}>
                        <Title titleType={TitleType.Standard} size={Size.Xs} textColor={Color.SoftBlue}>
                            ¿TRABAJÁS EN EL RUBRO INMOBILIARIO?
                        </Title>
                        <div className='max-h-[23.438rem] min-h-[23.438rem]'>
                            <img src="/images/helpSection/help-image-2.png" alt=""  className='max-w-[17.5rem] mx-auto'/>
                        </div>
                        <Subtitle size={Size.Sm}>
                            Concretá más operaciones, de una forma rápida y transparente
                        </Subtitle>
                        <BodyText size={Size.Xs} textColor={Color.BrandGrey}>
                            Tu experiencia en el mercado inmobiliario junto con
                            Bínculo, te permitirá comercializar de forma profesional y así concretar la mayor cantidad de operaciones.
                        </BodyText>
                    </HelpItem>
                    <HelpItem setPopUpOpen={setThirdHelpIsOpen}>
                        <Title titleType={TitleType.Standard} size={Size.Xs} textColor={Color.SoftBlue}>
                            ¿CONOCÉS A ALGUIEN QUE QUIERA VENDER SU PROPIEDAD?
                        </Title>
                        <div className='max-h-[23.438rem] min-h-[23.438rem]'>
                            <img src="/images/helpSection/help-image-3.png" alt=""  className='max-w-[17.5rem] mx-auto'/>
                        </div>
                        <Subtitle size={Size.Sm}>
                            Generá dinero sin saber del rubro
                            inmobiliario, fácil y transparente
                        </Subtitle>
                        <BodyText size={Size.Xs} textColor={Color.BrandGrey}>
                            Te brindamos una excelente
                            oportunidad de
                            generar dinero de forma fácil y sin
                            necesidad de tener experiencia o
                            conocimientos
                            previos en el rubro inmobiliario.
                        </BodyText>
                    </HelpItem>
                </HelpItemContainer>
            </Section>
            <Section height={useIsWindowSizeBellow(ScreenSize.Mobile)?35:59.813} withPaddings align={Position.Center} sectionId='us' imagePath={useIsWindowSizeBellow(ScreenSize.Mobile)?'/images/backgrounds/AboutUsBGMobile.png':'/images/backgrounds/AboutUsBG.png'} className='bg-diamond bg-100% md:bg-cover lg:bg-100% bg-center-negative-60  sm:bg-left-top md:bg-[center] pt-[3rem] sm:py-0'>
                <Title titleType={TitleType.InfoCardTitle} size={Size.Lg} textColor={Color.Diamond} className=' mb-9 '>
                    ¿Quiénes somos?
                </Title>
                <div ref={ref4} className={`inline-block ${visible4 ? 'animate-fadeInLeft visible' : 'invisible'}`}> 
                    <InfoCard textOrientation={HorizontalOrientation.End}>
                        <TextArea textAlign={HorizontalOrientation.Center} className='px-2'>
                            <BodyText size={Size.Lg} textColor={Color.DeepBlue} isThin>
                                {"Somos una empresa de <strong>tecnología inmobiliaria</strong> que está transformando la forma en la que se comercializa y se administran las distintas propiedades."
                                +"<br/><br/> Comprendemos las necesidades del mundo inmobiliario,"
                                +"sabemos que el tiempo de todos es muy valioso y por eso estamos comprometidos en brindar soluciones innovadoras a través de una experiencia superior."}
                            </BodyText>
                        </TextArea>
                    </InfoCard>
                </div>
            </Section>
            <Section height={63} withPaddings align={Position.Center} sectionId='ouradn'  className='bg-diamond py-8 md:py-20 ' isContentMax>
                <Title titleType={TitleType.Standard} size={Size.Lg} textColor={Color.DeepBlue} className=' mb-20 '>
                    Nuestro ADN
                </Title>
                {useIsWindowSizeBellow(ScreenSize.Mobile)?<SliderImageCard>
                    {reusableADNChildrens[0]}
                    {reusableADNChildrens[1]}
                    {reusableADNChildrens[2]}
                </SliderImageCard>:<></>}
                {useIsWindowSizeBellow(ScreenSize.Mobile)?<></>:<div className='space-y-8 md:space-y-32'>
                    <div ref={ref1} className={`inline-block ${visible1 ? 'animate-dropLeft visible' : 'invisible'}`}>
                        {reusableADNChildrens[0]}
                    </div>
                    <div ref={ref2} className={`inline-block ${visible2 ? 'animate-dropRight visible' : 'invisible'}`}>
                        {reusableADNChildrens[1]}
                    </div>
                    <div ref={ref3} className={`inline-block ${visible3 ? 'animate-dropLeft visible' : 'invisible'}`}>
                        {reusableADNChildrens[2]}
                    </div>
                </div>}
            </Section>
            <Section height={useIsWindowSizeBellow(ScreenSize.Mobile)?62:99} withPaddings align={Position.Center} sectionId='app' imagePath={useIsWindowSizeBellow(ScreenSize.Mobile)?'/images/backgrounds/DownloadAppBGMobile.png':'/images/backgrounds/DownloadAppBG.png'} className='bg-diamond md:bg-100% bg-left-top lg:bg-center py-16'>
                <TextArea textAlign={useIsWindowSizeBellow(ScreenSize.Desktop)?HorizontalOrientation.Center:HorizontalOrientation.Start} >
                    <Title titleType={TitleType.InfoCardTitle} size={Size.Lg} textColor={Color.BrandWhite}>
                        Descargá la APP
                    </Title>
                    <BodyText size={Size.Lg} textColor={Color.Diamond} isThin>
                        ¡Seguí los pasos y accedé a nuestra aplicación!
                    </BodyText>
                </TextArea>
                <img src="/images/backgrounds/DownloadAppBG2.png" alt=""  className='absolute top-0 left-0 right-0 mx-auto z-[-1] !mt-[45%] md:!mt-[50%] sm:w-[40%]  md:w-[60%] lg:!mt-[25%] xl:!mt-[20%] lg:w-auto'/>
                <DoubleSection>
                    <>
                        <AppDeviceOption deviceOption={DeviceOption.IOS} videoPath='/videos/donwloadIOS.mp4'>
                            <BodyText size={Size.Md} textColor={Color.Diamond}>
                                {"<strong>Presioná y accede al video</strong>"}
                            </BodyText>
                        </AppDeviceOption>
                        <AppStepGuideContainer>
                            <AppStepGuide iconPath={'/images/AppSection/IOSFirstStep.svg'} numberStep={1}>
                                <BodyText size={Size.Sm} textColor={Color.Diamond}>
                                    {"Ingresa desde tu navegador a "
                                    +"<strong>app.binculo.com<strong/>"}
                                </BodyText>
                            </AppStepGuide>
                            <AppStepGuide iconPath={'/images/AppSection/IOSSecondStep.svg'} numberStep={2}>
                                <BodyText size={Size.Sm} textColor={Color.Diamond}>
                                    "Clickeá en el botón “compartir”
                                </BodyText>
                            </AppStepGuide>
                            <AppStepGuide iconPath={'/images/AppSection/IOSThirdStep.svg'} numberStep={3}>
                                <BodyText size={Size.Sm} textColor={Color.Diamond}>
                                    Añadilo a la pantalla de inicio
                                </BodyText>
                            </AppStepGuide>
                        </AppStepGuideContainer>
                    </>
                    <>
                        <AppDeviceOption deviceOption={DeviceOption.Android} videoPath='/videos/donwloadAndroid.mp4'>
                            <BodyText size={Size.Md} textColor={Color.Diamond}>
                                {"<strong>Presioná y accede al video</strong>"}
                            </BodyText>
                        </AppDeviceOption>
                        <AppStepGuideContainer>
                            <AppStepGuide iconPath={'/images/AppSection/AndroidFirstStep.svg'} numberStep={1}>
                                <BodyText size={Size.Sm} textColor={Color.Diamond}>
                                    {"Ingresa desde tu navegador a "
                                    +"<strong>app.binculo.com<strong/>"}
                                </BodyText>
                            </AppStepGuide>
                            <AppStepGuide iconPath={'/images/AppSection/AndroidSecondStep.svg'} numberStep={2}>
                                <BodyText size={Size.Sm} textColor={Color.Diamond}>
                                    Clickeá en el botón “compartir”
                                </BodyText>
                            </AppStepGuide>
                            <AppStepGuide iconPath={'/images/AppSection/AndroidThirdStep.svg'} numberStep={3}>
                                <BodyText size={Size.Sm} textColor={Color.Diamond}>
                                    Agregar a la pantalla principal
                                </BodyText>
                            </AppStepGuide>
                        </AppStepGuideContainer>
                    </>
                </DoubleSection>
            </Section>
            <Footer>
                <FooterIremContainer>
                    <FooterItem>
                        <BodyText size={Size.Sm} textColor={Color.BrandBlue} className=' text-footer-link font-300'>
                            {"Copyright 2024 "+baseUrl}
                        </BodyText>
                        <GeneralLink type={LinkType.Footer} isDecorated={false} textColor={Color.DeepBlue} path='https://wa.me/541123772373'>
                            +54 11 2377 2373
                        </GeneralLink> 
                        <GeneralLink type={LinkType.Footer} isDecorated={false} textColor={Color.DeepBlue} path='mailto:binculo@gmail.com' >
                            binculo@gmail.com
                        </GeneralLink>
                    </FooterItem>
                    <FooterItem>
                        <BodyText size={Size.Sm} textColor={Color.BrandBlue} className=' text-footer-link font-300'>
                            {"Todos los derechos reservados"}
                        </BodyText>
                    </FooterItem>
                    <FooterItem>
                        <GeneralLink type={LinkType.Footer} isDecorated textColor={Color.DeepBlue} path='t&c' isExternal>
                            Términos y Condiciones
                        </GeneralLink>
                        <GeneralLink type={LinkType.Footer} isDecorated textColor={Color.DeepBlue} path='#p&p'isExternal>
                            Política de Privacidad
                        </GeneralLink>
                    </FooterItem>
                </FooterIremContainer>
                <IconsContainer>
                    <SocialIcons color='#234069' type={IconType.SocialInstagram} path='https://www.instagram.com/binculo.ar/' isExternal/>
                    <SocialIcons color='#234069' type={IconType.SocialLikedin} path='https://www.linkedin.com/company/binculo' isExternal/>
                    <SocialIcons color='#234069' type={IconType.SocialFacebook} path='https://www.facebook.com/binculo' isExternal/>
                </IconsContainer>
            </Footer>
        </>
    );
}

export default Home;