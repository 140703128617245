import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {BrowserRouter} from "react-router-dom";
import {Color} from "./Enums/Color.ts";
import GeneralLink,{LinkType} from './Components/Atoms/GeneralLink.tsx';
import Button, {ButtonType} from './Components/Atoms/Button.tsx';
import WhatsAppIcon from './Components/Atoms/WhatsAppIcon.tsx';
import Navbar from './Components/Organisms/NavBar.tsx'
import Home from './Components/Pages/Home.tsx'
import {ScreenSize} from "./Enums/ScreenSize.ts";
import {useIsWindowSizeBellow} from "./hooks/useIsWindowSizeBellow.ts";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar logoPath='./logo/BaredesLogo2.svg'>
        <GeneralLink isExternal={false} path='#us' type={LinkType.Navigation} textColor={Color.BrandGrey}>
          ¿QUIÉNES SOMOS?
        </GeneralLink>
        <GeneralLink isExternal={false} path='#ouradn' type={LinkType.Navigation}>
          NUESTRO ADN
        </GeneralLink>
        <Button type={ButtonType.Primary} path='https://app.binculo.com/' className=' font-600' isExternal>
          <img src='/images/header/loginIcon.png'/>
        </Button>
      </Navbar>
        <Home/>
        <WhatsAppIcon phoneNumber={"+541123772373"}/>
      </BrowserRouter>
    </div>
  );
}

export default App;
