import React, { ReactNode } from 'react';

interface IFooter {
    children: ReactNode;
}

function Footer(props: IFooter) {

    return (
        <div className=" bg-diamond px-4 md:px-8 xl:px-20 pb-4 md:pb-8 mt-auto">
            <div className='flex flex-col-reverse md:flex-row justify-between text-start max-w-[1440px] mx-auto'>
                {props.children}
            </div>  
        </div>
    );
}

export default Footer;